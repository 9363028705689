<template>
    <div class="share">
      
      <section class="event">
        <div class="container">
          <router-link :to="{ name: 'UserPublic', params: {serial: 516}}" class="snippest-block">
            <UserShareSnippest :serial="516"></UserShareSnippest>
          </router-link>
        </div>
      </section>

      <section>
        <div class="container">
          <button class="add-friend-btn" @click="openAddFriendModal"><img :src="require('@/assets/icons/Add_round_fill_grey.png')" />增加好友</button>
        </div>
      </section>
  
      <div class="py-4" v-if="followingSerials.length === 0">
        <p class="text-center" style="color: #aaa;">點擊上方的按鈕搜尋並追蹤好友！</p>
      </div>
  
      <section class="public-closet-list" v-if="followingSerials.length > 0">
        
        <div class="container">
          <h2>好友的衣櫥</h2>
  
          <router-link v-for="(f) in followingSerials" :key="f" :to="{ name: 'UserPublic', params: {serial: f}}" class="snippest-block">
            <UserShareSnippest :serial="f"></UserShareSnippest>
          </router-link>
  
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { mapActions } from 'vuex';
  
  import UserShareSnippest from '@/components/share/UserShareSnippest.vue';
  
  export default {
    name: 'FreindCloths',
    data() {
      return {
        readingBusyName: 'FOLLOWINGLISTREADING',
  
        init: false,
        followingSerials: [],
  
        showAddFriendModal: false,
      };
    },
    components: {
      UserShareSnippest,
    },
    computed: {
  
      },
    watch: {
    },
    async mounted() {
      await this.__refresh();
      },
    methods: {
      ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
      async __refresh() {
        this.appendComponentBusy(this.readingBusyName);
        try {
          this.followingSerials.splice(0, this.followingSerials.length);
          const followings = await this.$store.dispatch('api/getFollowingSerialList');
          for (const f of followings.list) {
            this.followingSerials.push(f);
          }
        } catch(e) {
          console.error(e);
          this.$emit('error', e);
        } finally {
          this.clearComponentBusy(this.readingBusyName);
        }
      },
      // closeAddFriendModal() {
      //   this.showAddFriendModal = false;
      // },
      openAddFriendModal() {
        this.setAddFriendShow(true);
        // this.showAddFriendModal = true;
      },
    }
  }
  </script>
  
  <style scoped>
    .event {
      /* position: sticky; */
      padding-top: 1rem;
      top: 0;
      background-color: #fff;
      z-index: 2;
    }

    .share {
      padding: 0 0 1rem;
    }
  
    .add-friend-btn {
      border: none;
      border-radius: .5rem;
      background-color: #fff;
      padding: .3rem;
      /* text-align: center; */
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-appearance: none;
      width: 100%;
      color: #212429;
      box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.41);
    }
  
    .add-friend-btn>img {
      display: block;
      margin-right: .5rem;
      width: 20px;
      height: 20px;
    }
  
    .public-closet-list {
      padding: 1rem 0;
    }
  
    .public-closet-list h2 {
      font-size: 18px;
      font-weight: 1000;
      color: var(--text-dark);
    }
  
    .snippest-block {
      display: block;
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: 3px 3px 2px 0 #EEEEEE;
      margin-bottom: .5rem;
    }
  
    .snippest-block:hover {
      text-decoration: none;
    }
  
  
  </style>
  