<template>
  <div class="userShareSnippest">
    <div class="info" @click="infoClicked">
      <div class="info-img">
        <img :src="userData.picUrl" v-if="userData && userData.picUrl"/>
      </div>

      <div class="info-text" v-if="userData">
        <p>{{userData.displayName}}</p>
        <span>@{{userData.publicId}}</span>
      </div>
    </div>

    <div class="cloths-row">
      <div class="cloth" v-for="(c) of publicClothUrls" :key="c">
        <img :src="c"/>
      </div>
      <p v-if="publicClothUrls.length === 0" style="color: #aaa;" class="mb-0">沒有公開的單品衣物</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserShareSnippest',
  data() {
    return {
      busy: false,
      inited: false,
      userData: null,
    };
  },
  components: {
  },
  props: {
    serial: {
      type: Number,
      required: true,
    }
  },
  beforeDestroy() {
    this.$emit('busy', false);
  },
  async mounted() {
    await this.__refresh();
  },
  computed: {
    publicClothUrls() {
      if (this.userData === null) {
        return false;
      }

      return this.userData.cloths.map((c) => {
        return c.imgs[0];
      });
    },
  },
  watch: {
    busy(val) {
      this.$emit('busy', val);
    },
  },
  methods: {
    async __refresh() {
      this.busy = true;
      try {
        const userData = await this.$store.dispatch('api/getUserPublicDataPromise', this.serial);
        this.userData = Object.assign({}, this.userData, userData);
        this.inited = true;
      } catch(e) {
        this.$emit('error', e);
      } finally {
        this.busy = false;
      }
    },
    infoClicked() {
      this.$emit('info-click');
    },
  }
}
</script>

<style scoped>
  .userShareSnippest {
    position: relative;
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
  }

  .info-img {
    position: relative;
    flex: 0 0 auto;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(196, 196, 196, 1);
  }

  .info-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info-text {
    padding: 0 1rem;
  }

  .info-text>p {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-dark);
    margin: 0;
  }

  .info-text>span {
    color: #8D8D8D;
  }

  .cloths-row {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  .cloth {
    width: 80px;
    height: 80px;
    background-color: rgba(196, 196, 196, 1);
    overflow: hidden;
    border-radius: .25rem;
    display: inline-block;
    margin-right: .5rem;
  }

  .cloth>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
